import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn = new BehaviorSubject<string>('LOGGED_OUT'); // default parameter will be LOGGED_OUT
  loggedInData = new Subject();

  constructor(private router: Router) { }

  /**
   * @returns which header to be shown
   * @param LOGGED_OUT shows default
   * @param LOGGED_IN shows when user loggin
   * @param OTHER show when route will be login, signup, forgot-password or reset-password
   */
  get isLoggedIn() {
    return this.loggedIn.asObservable()
  }

  /**
   * check auth token exist or not in local storage 
   * @returns true if token exist
   * else false
   */
  public checkAuthToken() {
    return localStorage.getItem('token') !== null;
  }

  logout() {
    this.loggedIn.next('LOGGED_OUT')
    localStorage.removeItem('token');
    localStorage.removeItem('myId')
    this.loggedInData.next('');
    this.router.navigate(['/homepage']);
  }

}

