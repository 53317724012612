import { Component } from '@angular/core';
// import { BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  
 
    constructor(private router: Router) {
    
      this.addGAScript();
      this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
          /** START : Code to Track Page View  */
          gtag('event', 'page_view', {
              page_path: event.urlAfterRedirects
          })
          /** END */
      })
  }
  /** Add Google Analytics Script Dynamically */
  addGAScript() {
      let gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
      document.head.prepend(gtagScript);
      /** Disable automatic page view hit to fix duplicate page view count  **/
      gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
  }
  title = 'strome-street-web';
  // constructor() { }
  /**
 * @param //subject behavior use
 */
  // public showImage = new Subject<any>();
  //  userImage = new BehaviorSubject(``);
  //  userImageObs = this.userImage.asObservable();
}


