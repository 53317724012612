<div class="wrraper">
    <div class="middle-sec pt-0">
        <section>
            <div class="container-1440">
                <div class="chat-div ">
                    <div class="chat-left">
                        <div class="left-profile">
                            <!-- <img src="assets/images/chat-img/user-icon.png"> -->
                            <img [src]="profileImageUrl ? profileImageUrl : 'assets/images/chat-img/user-icon.png'">
                            <div class="user-menu-div">
                                <!-- <img src="assets/images/chat-img/chat-icon.png" class="chat-icon cursor" style="border-radius: 0px;height: 46px;" alt="" (click)="openGroup()"> -->
                                <div (click)="openGroup()" class="chat-icon cursor">
                                    <span class="iconify" data-icon="bx:bx-message-rounded-add"></span>
                                    <p>New User</p>
                                </div>
                                <!-- <div class="dropdown">
                  <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" >New group</a>
                    <a class="dropdown-item" href="#">Create a room</a>
                    <a class="dropdown-item" href="#" >Profile</a>
                    <a class="dropdown-item" href="#">Archived</a>
                    <a class="dropdown-item" href="#">Starred</a>
                    <a class="dropdown-item" href="#">Labels</a>
                    <a class="dropdown-item" href="#">Settings</a>
                    <a class="dropdown-item" href="#">Log Out</a>
                  </div>
                </div> -->
                            </div>
                        </div>
                        <div class="search-div mb-3 ">
                            <!-- <input type="search" class="form-control" placeholder="Search or start new chat"> -->
                            <input type="search" class="form-control" placeholder="Search..." (keyup)="backSpaceEvent($event)" [(ngModel)]="searchRecentChat" (keyup)="serachRecentChatContact()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div class="chat-list ">
                            <!-- <ul *ngFor="let item of followinglistData" class="chat-list-ul" (click)="chatHistory(item?._id)"> -->
                            <!-- <ul *ngFor="let item of recentChatContactList" class="chat-list-ul" (click)="viewChatHistory(item?.senderId?._id)"> -->
                            <ul *ngFor="let item of recentChatContactList; let i = index" class="chat-list-ul" [ngClass]="item?.senderId?._id == myId ? ({'chat-tapped': receiverId == item?.receiverId?._id}) : ({'chat-tapped': receiverId == item?.senderId?._id})" (click)="(item?.senderId?._id == myId ? viewChatHistory(item?.receiverId?._id) : viewChatHistory(item?.senderId?._id)); (item?.senderId?._id == myId ? userTappedShowOnHeader(item?.receiverId?.profilePic, item?.receiverId?.fullName, item) : userTappedShowOnHeader(item?.senderId?.profilePic, item?.senderId?.fullName, item))">
                                <li class="chat-li1">
                                    <img [src]="(item?.senderId?._id == myId) ? (item?.receiverId ? item?.receiverId?.profilePic : 'assets/images/chat-img/user-img.png') : (item?.senderId ? item?.senderId?.profilePic : 'assets/images/chat-img/user-img.png')" alt="">
                                    <!-- <div class="active-chat"></div> -->
                                </li>
                                <li class="bottom-u">
                                    <div class="chat-li2 word-ellipsis">
                                        <h6>{{(item?.senderId?._id == myId) ? (item?.receiverId?.fullName) : (item?.senderId?.fullName)}}
                                        </h6>
                                        <label>
                      <!-- <img src="assets/images/chat-img/read-check.png">  -->
                      {{(item?.messages) ? (item?.messages[item?.messages?.length - 1].message) : ''}}
                    </label>
                                    </div>
                                    <div class="chat-li3">
                                        <p>{{item?.updatedAt | date: 'shortTime' }}</p>
                                        <div class=" d-flex  justify-content-end align-items-center">

                                            <div *ngIf="item?.totalUnreadMsg > 0" class="messages-nub">{{item?.totalUnreadMsg}}</div>
                                            <!-- <div class="dropdown chat-menu-drop">
                        <button class="btn chat-drop" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu chat-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" href="#">Mark as unread</a>
                          <a class="dropdown-item" href="#">Mute notification</a>
                          <a class="dropdown-item" href="#">Delete chat</a>
                          <a class="dropdown-item" href="#">pin chat</a>
                          <a class="dropdown-item" href="#">Starred</a>
                        </div>
                      </div> -->
                                        </div>
                                    </div>
                                </li>

                            </ul>
                            <!-- <ul class="chat-list-ul">
                <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
                  <div class="active-chat"></div>
                </li>
                <li class="bottom-u">
                  <div class="chat-li2">
                    <h6>Saloni</h6>
                    <label>
                      <img src="assets/images/chat-img/singel-check.png">
                      Hii Somya babu
                    </label>
                  </div>
                  <div class="chat-li3">
                    <p>10:10 AM</p>
                    <div class=" d-flex  justify-content-end align-items-center">
  
                      <div class="messages-nub">10</div>
                      <div class="dropdown chat-menu-drop">
                        <button class="btn chat-drop" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu chat-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" href="#">Mark as unread</a>
                          <a class="dropdown-item" href="#">Mute notification</a>
                          <a class="dropdown-item" href="#">Delete chat</a>
                          <a class="dropdown-item" href="#">pin chat</a>
                          <a class="dropdown-item" href="#">Starred</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>



              </ul> -->



                        </div>

                        <!-- my profile start-->
                        <div id="profile-id" class="profile-class">
                            <div class="back-profile">
                                <a href="javascript:void(0)" class="" onclick="closeProfile()"><i class="fa fa-long-arrow-left"
                    aria-hidden="true"></i> Profile</a>
                            </div>

                            <figure class="profile-figure">
                                <img src="assets/images/chat-img/user-icon.png">
                                <!-- <div class="change-profile"> -->
                                <div class="dropdown change-profile">
                                    <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-camera" aria-hidden="true"></i> <br> CHANGE <br> PROFILE <br> PHOTO
                  </button>
                                    <div class="dropdown-menu change-profile-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#">view Photo</a>
                                        <a class="dropdown-item" href="#">Take photo</a>
                                        <a class="dropdown-item" href="#">Uploade photo</a>
                                        <a class="dropdown-item" href="#">Remove photo</a>
                                    </div>
                                </div>
                                <!-- <i class="fa fa-camera" aria-hidden="true"></i>
              </div> -->
                            </figure>
                            <div class="name-div mt-5">
                                <h6>Your Name</h6>
                                <p class="mt-3">Princy</p>
                            </div>
                            <div class="name-div mt-3">
                                <h6>About</h6>
                                <p class="mt-3">Princy kumari</p>
                            </div>

                        </div>
                        <!-- my profile End-->
                        <!-- new group Star-->
                        <div id="new-group-id" class="profile-class ">
                            <div class="back-profile">
                                <a href="javascript:void(0)" class="" (click)="closeGroup()"><i class="fa fa-long-arrow-left"
                    aria-hidden="true"></i> New Chat</a>
                            </div>
                            <!-- <div class="search-div mb-3 ">
                <input type="search" class="form-control" placeholder="Search or start new chat">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div> -->
                            <div class="chat-list mt-3">
                                <!-- <ul class="chat-list-ul align-items-center">
                  <li class="chat-li1"><i class="fa fa-user-plus" aria-hidden="true"></i></li>
                  <li class="bottom-u" style="height: 50px;">
                    <div class="chat-li2">
                      <h6>New group</h6>
                    </div>
                  </li>
                </ul> -->
                                <!-- <ul *ngFor="let item of followinglistData" class="chat-list-ul"
                  (click)="chatWithNewUser(item?.userId?._id)"> -->
                                <ul *ngFor="let item of followinglistData" class="chat-list-ul" (click)="chatWithNewUser(item);userTappedShowOnHeader(item.userId.profilePic, item.userId.fullName)">
                                    <li class="chat-li1"><img [src]="item?.userId ? item?.userId?.profilePic : 'assets/images/chat-img/user-img.png'" alt="">
                                        <div class="active-chat"></div>
                                    </li>
                                    <li class="bottom-u">
                                        <div class="chat-li2">
                                            <h6>{{item?.userId?.fullName}}</h6>
                                            <label>{{item?.userId?.userName}}</label>
                                        </div>
                                    </li>
                                </ul>
                                <!--  -->
                                <!-- <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
                    <div class="active-chat"></div>
                  </li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
                    <div class="active-chat"></div>
                  </li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
  
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
  
  
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
  
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
                    <div class="active-chat"></div>
                  </li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
                    <div class="active-chat"></div>
                  </li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
                    <div class="active-chat"></div>
                  </li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
  
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
  
  
                </ul>
                <ul class="chat-list-ul">
                  <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
                  <li class="bottom-u">
                    <div class="chat-li2">
                      <h6>sonam kumari</h6>
                      <label> About me</label>
                    </div>
                  </li>
  
                </ul> -->
                            </div>


                        </div>





                    </div>


                    <!--  -->
                    <!-- 612 -->




                    <div class="chat-right">
                        <ng-container *ngIf="userTapped">
                            <div class="chat-person ">
                                <div class="profie-name-img">
                                    <!-- <img src="assets/images/chat-img/user-img.png" class="img-u"> -->
                                    <img [src]="profilePic ? profilePic : 'assets/images/chat-img/user-img.png'" class="img-u">

                                    <div>
                                        <!-- <h3>Princy Sexena
                    </h3> -->
                                        <h3>{{tappedUserName}}
                                        </h3>

                                        <!-- <label>Online</label> -->
                                    </div>
                                </div>
                                <div class="chat-person2">
                                    <!-- <i class="fa fa-search" aria-hidden="true"></i> -->
                                    <!-- <div class="attachment-div">
                  <div class="dropdown">
                    <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <img src="assets/images/chat-img/attachment-icon.png">
                    </button>
                    <div class="dropdown-menu attachment-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item " href="#">
                        <p>Photos/Videos</p><img src="assets/images/chat-img/gallery.png">
                      </a>
                      <a class="dropdown-item" href="#">
                        <p>Camera</p> <img src="assets/images/chat-img/camra.png">
                      </a>
                      <a class="dropdown-item" href="#">
                        <p>Document</p><img src="assets/images/chat-img/document.png">
                      </a>
                      <a class="dropdown-item" href="#">
                        <p>Contact</p><img src="assets/images/chat-img/contact.png">
                      </a>
                      <a class="dropdown-item" href="#">
                        <p>Location</p> <img src="assets/images/chat-img/map.png">
                      </a>
                      <a class="dropdown-item" href="#">
                        <p>Audio</p> <img src="assets/images/chat-img/audio-icon.png">
                      </a>
                    </div>
                  </div>
                </div> -->
                                    <!-- <div class="dropdown">
                                        <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                                        <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item " data-toggle="modal" data-target="#blockModal"> Block</a> -->
                                            <!-- <a class="dropdown-item "> Contact Info</a> -->
                                            <!-- <a class="dropdown-item" href="#" > Group Info</a>
                    <a class="dropdown-item" href="#" > Broadcost Info</a>
                    <a class="dropdown-item" href="#" > Online User</a>
                    <a class="dropdown-item" href="#" > Online User 2</a> -->
                                        <!-- </div>
                                    </div> -->
                                </div>
                            </div>






                            <!--  -->
                            <div id="right-main">




                                <div #contentArea class="chat-hight">
                                    <!-- <div class="older-text"><label>01/09/2020</label></div> -->
                                    <!-- {{particularChat?.messages | json}} -->
                                    <ng-container *ngFor="let item of particularChat?.messages">
                                        <div class="left-chat" *ngIf="item?.receiverId == myId">
                                            <div class="left-chat-text">
                                                <div class="text-msg">
                                                    <p>{{item?.message || '--'}}</p>
                                                    <!-- <p>how are you</p> -->
                                                    <span>{{item?.createdAt | date:'shortTime' || '--'}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="right-chat" *ngIf="item?.receiverId != myId">
                                            <div class="right-chat-text">
                                                <div class="text-msg">
                                                    <!-- <p>Hiii</p> -->
                                                    <p>{{item?.message || '--'}}</p>
                                                    <!-- <p>how are you</p> -->
                                                    <!-- <span>10:55 PM</span> -->
                                                    <span>{{item?.createdAt | date:'shortTime' || '--'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- <div class="right-chat">
                  <div class="right-chat-text">

                    <div class="text-msg">
                      <p>Hiii</p>
                      <p>how are you</p>
                      <span>10:55 PM
                        <img src="assets/images/chat-img/read-check.png">
                      </span>
                    </div>
                  </div>
                </div>
                <div class="left-chat">
                  <div class="left-chat-text">
                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">
                      <span>10:55 PM</span>
                    </div>
                  </div>
                </div> -->

                                    <!-- to be commented -->
                                    <!-- <div class="left-chat">
                  <div class="left-chat-text">
                    <div class="text-msg">
                      <p>Hiii</p>
                      <p>how are you</p>
                      <span>10:55 PM</span>
                    </div>

                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="right-chat">
                  <div class="right-chat-text">

                    <div class="text-msg">
                      <p>Hiii</p>
                      <p>how are you</p>
                      <span>10:55 PM
                        <img src="assets/images/chat-img/read-check.png">
                      </span>

                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="left-chat">

                  <div class="left-chat-text">
                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">
                      <span>10:55 PM</span>
                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="right-chat">
                  <div class="right-chat-text">

                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">

                      <span>10:55 PM
                        <img src="assets/images/chat-img/singel-check.png">
                      </span>
                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="right-chat">
                  <div class="right-chat-text">

                    <div class="text-msg">
                      <p>Hiii</p>
                      <p>how are you</p>
                      <span>10:55 PM
                        <img src="assets/images/chat-img/read-check.png">
                      </span>

                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="right-chat">
                  <div class="right-chat-text">

                    <div class="text-msg">
                      <p>Hiii</p>
                      <p>how are you</p>
                      <span>10:55 PM
                        <img src="assets/images/chat-img/read-check.png">
                      </span>

                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="left-chat">

                  <div class="left-chat-text">
                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">
                      <span>10:55 PM</span>
                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->

                                    <!-- <div class="left-chat">
                  <div class="left-chat-text">
                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">
                      <div class="pdf-div">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document.pdf
                      </div>
                    </div>
                    <div class="document-text"><span>1page . PDF . 22KB</span> <span>10:55 PM </span></div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->

                                    <!-- <div class="right-chat">
                  <div class="right-chat-text">
                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">
                      <div class="pdf-div">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document.pdf
                      </div>
                    </div>
                    <div class="document-text"><span>1page . PDF . 22KB</span> <span>10:55 PM <img
                          src="assets/images/chat-img/dubal-check.png"></span></div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->

                                    <!-- <div class="left-chat">
                  <div class="left-chat-text url">
                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">
                    </div>
                    <div class="text-msg">
                      <p>Hiii</p>
                      <label>how are you</label>
                      <label>file:///Users/manishpandey/Desktop/yellow-sheed/yellowseed-html/chat.html</label>
                    </div>
                    <span>10:55 PM</span>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->

                                    <!-- 
                <div class="left-chat">
                  <div class="left-chat-text url">
                    <div class="document-msg">
                      <video controls>
                        <source src="assets/images/chat-img/Video.mp4" type="video/mp4">
                        <source src="assets/images/chat-img/Video.mp4" type="video/ogg">
                      </video>
                    </div>
                    <span>10:55 PM</span>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="right-chat">
                  <div class="right-chat-text url">
  
                    <div class="document-msg">
                      <img src="assets/images/chat-img/rose.png">
                    </div>
                    <div class="text-msg">
                      <p>Hiii</p>
                      <label>how are you</label>
                      <label>file:///Users/manishpandey/Desktop/yellow-sheed/yellowseed-html/chat.html</label>
                    </div>
                    <div class="text-right"> <span>10:55 PM <img src="assets/images/chat-img/read-check.png"></span></div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="left-chat">
                  <div class="left-chat-text url">
                    <div class="left-chat-text">
                      <ul class="multy-ul">
                        <li><img src="assets/images/chat-img/rose.png"><span>10:55 PM </span></li>
                        <li><img src="assets/images/chat-img/rose.png"><span>10:55 PM </span></li>
                        <li><img src="assets/images/chat-img/rose.png"><span>10:55 PM </span></li>
                        <li><a href="image_view.html"><span>10:55 PM </span><img src="assets/images/chat-img/rose.png">
                            <div class="multy-text">+23</div>
                          </a></li>
                      </ul>
                    </div>
  
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->




                                    <!-- <div class="right-chat">
                  <div class="right-chat-text url">
  
                    <ul class="multy-ul">
                      <li><img src="assets/images/chat-img/rose.png"><span>10:55 PM </span></li>
                      <li><img src="assets/images/chat-img/rose.png"><span>10:55 PM </span></li>
                      <li><img src="assets/images/chat-img/rose.png"><span>10:55 PM </span></li>
                      <li><a href="image_view.html"><span>10:55 PM </span><img src="assets/images/chat-img/rose.png">
                          <div class="multy-text">+23</div>
                        </a></li>
                    </ul>
  
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="right-chat">
                  <div class="right-chat-text url">
  
                    <div class="contact-card">
                      <img src="assets/images/chat-img/user-img.png" class="contact-img"> Sarthak jio.
                      <div class="text-right"> <span>10:55 PM <img src="assets/images/chat-img/read-check.png"></span></div>
                    </div>
                    <div class="add-contact">
                      <p>Message</p>
                      <p>Add to a group</p>
                    </div>
  
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="left-chat">
  
                  <div class="left-chat-text url">
                    <div class="contact-card">
                      <img src="assets/images/chat-img/user-img.png" class="contact-img"> Sarthak jio.
                      <div class="text-right"> <span>10:55 PM </span></div>
                    </div>
                    <div class="add-contact">
                      <p>Message</p>
                      <p>Add to Contact</p>
                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="left-chat">
  
                  <div class="left-chat-text url">
                    <div class="forwor-card">
                      <div class="forword-msg">
                        <h6>You</h6>
                        Hello !
                      </div>
                      Hiiii....
                      <div class="text-right"> <span>10:55 PM</span></div>
                    </div>
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="right-chat">
                  <div class="right-chat-text url">
                    <div class="forwor-card">
                      <div class="forword-msg">
                        <h6>You</h6>
                        Hello !
                      </div>
                      Hiiii....
                      <div class="text-right"> <span>10:55 PM <img src="assets/images/chat-img/read-check.png"></span></div>
                    </div>
  
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="left-chat">
                  <div class="left-chat-text url">
                    <div class="cop-profile">
                      <img src="assets/images/chat-img/user-icon.png">
                      <div>
                        <h5>Sonam kumari</h5> <label>The quint</label>
                      </div>
                    </div>
                    <img src="assets/images/chat-img/rose.png" class="cop-img">
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->


                                    <!-- <div class="right-chat">
                  <div class="right-chat-text url">
  
                    <div class="cop-profile">
                      <img src="assets/images/chat-img/user-icon.png">
                      <div>
                        <h5>Sonam kumari</h5> <label>The quint</label>
                      </div>
                    </div>
                    <img src="assets/images/chat-img/rose.png" class="cop-img">
  
                    <div class="dropdown chat-drop-down">
                      <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu chat-drop-down-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " href="#"> Message info</a>
                        <a class="dropdown-item" href="#">Reply</a>
                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#forgot-pasword">Forward
                          message</a>
                        <a class="dropdown-item" href="#">Star message</a>
                        <a class="dropdown-item" data-toggle="modal" data-target="#delete-message" href="#">Delete
                          message</a>
                      </div>
                    </div>
                  </div>
                </div> -->

                                </div>

                                <div class="comment-div comment-div22 mt-3">
                                    <!-- <i class="fa fa-smile-o" aria-hidden="true"></i> -->
                                    <div class="chat-input-div">
                                        <input type="text" class="form-control" placeholder="Type a messages" [(ngModel)]="newMessage" (keyup.enter)="sendMessage()">
                                        <i class="fa fa-paper-plane" aria-hidden="true" (click)="sendMessage()"></i>
                                    </div>
                                    <!-- <div class="audio-div">
                  <i class="fa fa-microphone" aria-hidden="true"></i>
                </div> -->
                                    <!-- <div class="audio-div">
                  <button class="btn  btn-link" type="button">
                    <img src="assets/images/chat-img/attachment-icon.png">
                  </button>
                </div> -->

                                    <!-- <div id="myForm4">
                  <div class="recording-div">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <div class="progress">
                      <div class="progress-bar" aria-valuemin="0" aria-valuemax="100">
                      </div>
                    </div>
                    <p>Recording</p>
                    <img src="assets/images/chat-img/send.png" >
                  </div>
                </div> -->
                                </div>


                            </div>
                        </ng-container>

                        <!-- user not selected -->
                        <ng-container *ngIf="!userTapped">
                            <!-- <h1>helloo</h1> -->
                            <div class="chat-hight">
                                <div class="temp-chart-text">
                                    <img src="/assets/images/chat-background.jpeg">
                                    <h1>TAP USER TO START
                                        <h1 class="conver">CONVERSATION</h1>
                                        <br>
                                    </h1>
                                </div>
                            </div>
                        </ng-container>



                        <!-- your-profile  -->
                        <div id="contact-info" class="contact-info-div">
                            <div class="contact-div">
                                <div class="your-cros"> <a href="javascript:void(0)" class="closebtn" onclick="closecontact()">&times;
                    Contact Info</a>
                                </div>
                                <figure class="contact-user-img">
                                    <img src="assets/images/chat-img/user-img.png">
                                </figure>
                                <h6>John Thomas</h6>
                                <p>Online</p>
                            </div>
                            <div class="media-divs">
                                <div class="media-divs2" onclick="openmedia()">
                                    <h6>Media, Link and Docs</h6>
                                    <p> <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                                <ul>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                </ul>
                            </div>
                            <div class="media-divs">
                                <div class="media-divs2">
                                    <h6 style="  color: #000;">Mute notification</h6>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                        <label class="form-check-label" for="exampleCheck1"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="media-divs">
                                <div class="media-divs2">
                                    <h6 style="  color: #000;">Starred Message</h6>
                                    <p> <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                            </div>
                            <div class="media-divs">
                                <div class="media-divs2">
                                    <h6>About and phone number</h6>
                                </div>
                                <p class="mt-3">HEllo !</p>
                                <p>+91 9874586217</p>
                            </div>
                            <div class="media-divs" data-toggle="modal" data-target="#block">
                                <div class="media-divs5">
                                    <i class="fa fa-ban" aria-hidden="true"></i>
                                    <h6>Block Contact</h6>

                                </div>
                            </div>
                            <div class="media-divs" data-toggle="modal" data-target="#report">
                                <div class="media-divs5">
                                    <i class="fa fa-flag" aria-hidden="true" style="color: #df3333;"></i>
                                    <h6 style="color: #df3333;"> Report Group </h6>

                                </div>
                            </div>
                            <div class="media-divs" data-toggle="modal" data-target="#delete">
                                <div class="media-divs5">
                                    <i class="fa fa-trash" aria-hidden="true" style="color: #df3333;"></i>
                                    <h6 style="color: #df3333;">Delete Chat</h6>

                                </div>
                            </div>

                        </div>
                        <!-- your-profile end -->

                        <!-- group-profile  -->
                        <div id="group-info-div" class="contact-info-div">
                            <div class="contact-div">
                                <div class="your-cros"> <a href="javascript:void(0)" class="closebtn" onclick="closegroup()">&times;
                    Group Info</a>
                                </div>
                                <figure class="contact-user-img">
                                    <img src="assets/images/chat-img/user-img.png">
                                </figure>
                                <h6>Group Name</h6>
                                <p>Created 2/5/2016 at 5:42 PM</p>
                            </div>
                            <div class="media-divs">
                                <div class="media-divs2">
                                    <h6>Media, Link and Docs</h6>
                                    <p> <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                                <ul>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                </ul>
                            </div>
                            <div class="media-divs">
                                <div class="media-divs2">

                                    <h6 style="  color: #000;">Mute notification</h6>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                        <label class="form-check-label" for="exampleCheck1"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="media-divs">
                                <div class="media-divs2">
                                    <h6 style="  color: #000;">Starred Message</h6>
                                    <p> <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                            </div>
                            <div class="media-divs ">
                                <div class=" search-icon"><i class="fa fa-search" aria-hidden="true" data-toggle="modal" data-target="#search"></i></div>

                                <div class="new-call new-call3 mt-3"><a href="#">Add Participants</a>
                                    <h6> 34 Participant</h6>
                                </div>
                                <div class="pol-profie mt-3">
                                    <div class="pol-div1">
                                        <figure><img src="assets/images/chat-img/user-img.png">
                                            <div class="active-chat"></div>
                                        </figure>
                                        <div>
                                            <h5>Priya Bansal</h5>

                                        </div>
                                    </div>
                                </div>
                                <div class="pol-profie mt-3">
                                    <div class="pol-div1">
                                        <figure><img src="assets/images/chat-img/user-img.png">
                                            <div class="active-chat"></div>
                                        </figure>
                                        <div>
                                            <h5>Priya Bansal</h5>

                                        </div>
                                    </div>
                                </div>
                                <div class="pol-profie mt-3">
                                    <div class="pol-div1">
                                        <figure><img src="assets/images/chat-img/user-img.png">
                                            <div class="active-chat"></div>
                                        </figure>
                                        <div>
                                            <h5>Priya Bansal</h5>

                                        </div>
                                    </div>
                                </div>
                                <div class="pol-profie mt-3">
                                    <div class="pol-div1">
                                        <figure><img src="assets/images/chat-img/user-img.png">
                                            <div class="active-chat"></div>
                                        </figure>
                                        <div>
                                            <h5>Priya Bansal</h5>

                                        </div>
                                    </div>
                                </div>
                                <div class="pol-profie mt-3">
                                    <div class="pol-div1">
                                        <figure><img src="assets/images/chat-img/user-img.png">
                                            <div class="active-chat"></div>
                                        </figure>
                                        <div>
                                            <h5>Priya Bansal</h5>

                                        </div>
                                    </div>
                                    <button class="btn btn-post">Group Admin</button>
                                </div>
                                <div class="pol-profie mt-3">
                                    <div class="pol-div1">
                                        <figure><img src="assets/images/chat-img/user-img.png">
                                            <div class="active-chat"></div>
                                        </figure>
                                        <div>
                                            <h5>Priya Bansal</h5>

                                        </div>
                                    </div>
                                </div>
                                <div class="pol-profie mt-3">
                                    <div class="pol-div1">
                                        <figure><img src="assets/images/chat-img/user-img.png">
                                            <div class="active-chat"></div>
                                        </figure>
                                        <div>
                                            <h5>Priya Bansal</h5>

                                        </div>
                                    </div>
                                </div>
                                <div class="pol-profie mt-3">
                                    <h5><i class="fa fa-chevron-down pr-2 pl-3" aria-hidden="true"></i> 5 More</h5>
                                </div>

                            </div>
                            <div class="media-divs" data-toggle="modal" data-target="#delete">
                                <div class="media-divs5">
                                    <i class="fa fa-sign-out" aria-hidden="true" style="color: #df3333;"></i>
                                    <h6 style="color: #df3333;">Exit group</h6>

                                </div>
                            </div>
                            <div class="media-divs" data-toggle="modal" data-target="#report">
                                <div class="media-divs5">
                                    <i class="fa fa-flag" aria-hidden="true" style="color: #df3333;"></i>
                                    <h6 style="color: #df3333;"> Report Group </h6>

                                </div>
                            </div>

                        </div>
                        <!-- group-profile end -->









                        <!-- media  -->

                        <div id="media-div" class="contact-info-div">
                            <div class="contact-div">
                                <div class="your-cros"> <a href="javascript:void(0)" class="closebtn"><i class="fa fa-long-arrow-left"
                      aria-hidden="true"></i>
                  </a>
                                </div>
                                <ul class="nav nav-pills media-ul " id="pills-tab" role="tablist">
                                    <li class="nav-item media-li">
                                        <a class="nav-link media-a active" id="pills-media-tab" data-toggle="pill" href="#pills-media" role="tab" aria-controls="pills-media" aria-selected="true">MEDIA</a>
                                    </li>
                                    <li class="nav-item media-li">
                                        <a class="nav-link media-a" id="pills-docs-tab" data-toggle="pill" href="#pills-docs" role="tab" aria-controls="pills-docs" aria-selected="false">DOCS</a>
                                    </li>
                                    <li class="nav-item media-li">
                                        <a class="nav-link media-a" id="pills-links-tab" data-toggle="pill" href="#pills-links" role="tab" aria-controls="pills-links" aria-selected="false">LINKS</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-media" role="tabpanel" aria-labelledby="pills-media-tab">
                                        <div class="media-link">
                                            <div class="media-divs p-0">
                                                <ul>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                    <li><img src="assets/images/chat-img/rose.png"></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-docs" role="tabpanel" aria-labelledby="pills-docs-tab">
                                        <div class="media-link">
                                            <div class="left-chat">

                                                <div class="left-chat-text">
                                                    <div class="document-msg">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="pdf-div">
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document.pdf
                                                        </div>
                                                    </div>
                                                    <div class="document-text"><span>1page . PDF . 22KB</span> <span>10:55 PM </span></div>
                                                </div>

                                            </div>
                                            <div class="left-chat">

                                                <div class="left-chat-text">
                                                    <div class="document-msg">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="pdf-div">
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document.pdf
                                                        </div>
                                                    </div>
                                                    <div class="document-text"><span>1page . PDF . 22KB</span> <span>10:55 PM </span></div>
                                                </div>

                                            </div>
                                            <div class="left-chat">

                                                <div class="left-chat-text">
                                                    <div class="document-msg">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="pdf-div">
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document.pdf
                                                        </div>
                                                    </div>
                                                    <div class="document-text"><span>1page . PDF . 22KB</span> <span>10:55 PM </span></div>
                                                </div>

                                            </div>
                                            <div class="left-chat">

                                                <div class="left-chat-text">
                                                    <div class="document-msg">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="pdf-div">
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document.pdf
                                                        </div>
                                                    </div>
                                                    <div class="document-text"><span>1page . PDF . 22KB</span> <span>10:55 PM </span></div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-links" role="tabpanel" aria-labelledby="pills-links-tab">
                                        <div class="media-link">
                                            <div class="left-chat">
                                                <div class="left-chat-text">
                                                    <div class="link-div">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="link-div-text">
                                                            <p>https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                            <label>www.Bootstrap.com</label>
                                                        </div>
                                                    </div>
                                                    <p class="link-p">https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                </div>
                                            </div>
                                            <div class="left-chat">
                                                <div class="left-chat-text">
                                                    <div class="link-div">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="link-div-text">
                                                            <p>https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                            <label>www.Bootstrap.com</label>
                                                        </div>
                                                    </div>
                                                    <p class="link-p">https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                </div>
                                            </div>
                                            <div class="left-chat">
                                                <div class="left-chat-text">
                                                    <div class="link-div">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="link-div-text">
                                                            <p>https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                            <label>www.Bootstrap.com</label>
                                                        </div>
                                                    </div>
                                                    <p class="link-p">https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                </div>
                                            </div>
                                            <div class="left-chat">
                                                <div class="left-chat-text">
                                                    <div class="link-div">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="link-div-text">
                                                            <p>https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                            <label>www.Bootstrap.com</label>
                                                        </div>
                                                    </div>
                                                    <p class="link-p">https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                </div>
                                            </div>
                                            <div class="left-chat">
                                                <div class="left-chat-text">
                                                    <div class="link-div">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="link-div-text">
                                                            <p>https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                            <label>www.Bootstrap.com</label>
                                                        </div>
                                                    </div>
                                                    <p class="link-p">https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                </div>
                                            </div>
                                            <div class="left-chat">
                                                <div class="left-chat-text">
                                                    <div class="link-div">
                                                        <img src="assets/images/chat-img/rose.png">
                                                        <div class="link-div-text">
                                                            <p>https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                            <label>www.Bootstrap.com</label>
                                                        </div>
                                                    </div>
                                                    <p class="link-p">https://getbootstrap.com/docs/4.0/components/navs/</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- media end -->
                            <!-- brodcast  -->
                            <div id="Broadcost-info-div" class="contact-info-div">
                                <div class="contact-div">
                                    <div class="your-cros"> <a href="javascript:void(0)" class="closebtn">&times;
                      Broadcast ingo</a>
                                    </div>
                                    <figure class="contact-user-img">
                                        <img src="assets/images/chat-img/brodcast.png">
                                    </figure>
                                    <h6>Broadcast</h6>
                                    <p>Created 2/5/2016 at 5:42 PM</p>
                                </div>

                                <div class="media-divs">

                                    <div class="media-divs2">
                                        <i class="fa fa-lock" aria-hidden="true"></i>
                                        <h6>Encryption</h6>
                                    </div>
                                    <p class="mt-3">Message and calls are end-to-end encrypted. Top to learn more</p>
                                </div>

                                <div class="media-divs">
                                    <div class="new-call new-call3 mt-3">
                                        <h6> 3 recipients</h6>
                                        <i class="fa fa-search" aria-hidden="true" data-toggle="modal" data-target="#search"></i>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure class="add-brod"><i class="fa fa-user-plus" aria-hidden="true"></i>
                                            </figure>
                                            <div>
                                                <h5>Eidt recipients</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="media-divs" data-toggle="modal" data-target="#delete">
                                    <div class="media-divs5">
                                        <i class="fa fa-trash" aria-hidden="true" style="color: #df3333;"></i>
                                        <h6 style="color: #df3333;">Delete brodcast list</h6>

                                    </div>
                                </div>



                            </div>
                            <!-- brodcast  end-->
                            <div id="onlineuser-info-div" class="contact-info-div">
                                <div class="contact-div user-info">
                                    <a href="javascript:void(0)" class="closebtn">&times;</a>
                                    <h6>John Thomas</h6>
                                    <p> <label class="chat-activ"></label>Online</p>
                                </div>
                                <div class="media-divs">
                                    <div class="new-call new-call3 mt-3"><a href="#">Add Participants</a>
                                        <h6> 34 Participant</h6>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                        <button class="btn btn-post">Group Admin</button>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="pol-profie mt-3">
                                        <div class="pol-div1">
                                            <figure><img src="assets/images/chat-img/user-img.png">
                                                <div class="active-chat"></div>
                                            </figure>
                                            <div>
                                                <h5>Priya Bansal</h5>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="pol-profie mt-3">
                                        <h5><i class="fa fa-chevron-down p-5" aria-hidden="true"></i> 5 More</h5>
                                    </div>

                                    <div class="exit-group mt-3">
                                        <p><img src="assets/images/chat-img/block.png"> Block Contact</p>
                                        <p><img src="assets/images/chat-img/report.png"> Report Group</p>
                                        <p><img src="assets/images/chat-img/clear.png">Clear Chat</p>
                                    </div>

                                </div>

                            </div>

                            <div id="onlineuser-info-div2" class="contact-info-div">
                                <div class="contact-div user-info">
                                    <a href="javascript:void(0)" class="closebtn">&times;</a>
                                    <h6>John Thomas</h6>
                                    <p> +91 9650225013</p>
                                </div>
                                <div class="media-divs">
                                    <a href="#">Create New Contact</a>
                                </div>
                                <div class="media-divs">
                                    <a href="#">Add To Existing Contact</a>
                                </div>
                                <div class="media-divs">
                                    <a href="#">Share Contact</a>
                                </div>

                                <div class="media-divs">


                                    <div class="exit-group mt-3" style="border-top: none;">
                                        <p><img src="assets/images/chat-img/block.png"> Block Contact</p>
                                        <p><img src="assets/images/chat-img/report.png"> Report Group</p>
                                        <p><img src="assets/images/chat-img/clear.png">Clear Chat</p>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <!--  -->
                    </div>








                </div>
            </div>
        </section>

    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="blockModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <h1 class="text-center">Are you sure do you want to block this person ?</h1>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn btn-secondary globleBtn" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary globleBtn">Block </button>
            </div>
        </div>
    </div>
</div>