import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/provider/auth.service';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LogoutComponent>, private authService: AuthService) { }

  ngOnInit(): void {
  }

  // Logout Function call from common service
  Logout() {
    this.authService.logout()
    this.dialogRef.close();
  }

  // Material Dialog Close After Open Second Dialog
  closeDialog() {
    this.dialogRef.close();
  }
}

