<!-- <div class="wrapper fadeInDown"> -->
<div class="wrapper">
    <!-- <div class="bannerMainDiv" style="background-image: url('../../../assets/images/bannerNew12.jpeg');"> -->
    <div class="bannerMainDiv" style="background: #ffffff;">
        <div class="postContentDiv">
            <!-- trending post section -->
            <div class="trendingPostMainDiv">
                <div class="postTypeHeading">
                    <h2 class="heading" style="color: black;">TRENDING</h2>
                </div>
                <div class="trendingPost row trendingMainPostDiv">
                    <!-- tranding post -->
                    <ng-container *ngIf="trandingPostArray.length; else trendingSkeleton">
                        <div *ngFor="let item of trandingPostArray" class="postDiv col-xl-4 col-lg-4 col-md-6 col-sm-2">
                            <div class="imageDiv">
                                <img *ngIf="item?.mediaType == 'IMAGE'" class="postImage cardcontainer" [src]="item?.imageLinks ? item?.imageLinks[0] : '../../../assets/images/postA4.jpeg'" alt="img" (click)="routerToOtherPostDescription(item)">
                                <video *ngIf="item?.mediaType == 'VIDEO'" class="postImageTop postImage cardcontainer videoBackground" (click)="routerToOtherPostDescription(item)" [src]="item?.videoLink" autoplay onloadedmetadata="this.muted = true" loop #videoplayer>
                                    <source type="video/mp4" />
                                    <track label="English" kind="captions">
                                    Browser not supported
                                </video>
                            </div>
                            <div class="postDescriptinDiv">
                                <p class="postDetails word-ellipsis postTitle">{{item?.description || '--'}}</p>
                                <p class="postTime">{{item?.createdAt | date :'medium'}}</p>
                            </div>
                        </div>
                    </ng-container>
                    <!-- trending skeleton -->
                    <ng-template #trendingSkeleton>
                        <div class="skeleton postDiv col-xl-4 col-lg-4 col-md-6 col-sm-2" *ngFor="let item of generateFake(8)">
                            <div class="item-wrap">
                                <div class="item-img">
                                    <ngx-skeleton-loader animation="progress" [theme]="skeletonImageTheme">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div class="viewMoreDivAtBottom">
                    <Span class="viewMore" routerLink='/activity/tranding'>View more trending post
                        <i class="fa fa-arrow-right" aria-hidden="true"></i></Span>
                </div>
            </div>
            <!-- end trending post section -->

            <!-- categories section -->
            <div class="trendingPostMainDiv">
                <div class="postTypeHeading">
                    <h2 class="heading" style="color: black;">CATEGORIES</h2>
                </div>
                <div class="trendingPost row">
                    <!-- category -->
                    <ng-container *ngIf="categoryListArray.length else categorySkeleton">
                        <div *ngFor="let item of categoryListArray | slice:0:6" class="catPostDiv col-md-6 col-xl-4 col-lg-4 col-sm-2">
                            <div class="imageDiv" (click)="categoriesRouting(item?.categoryName)">
                                <img class="postImage cardcontainer" [src]="item?.image ? item?.image : '../../../assets/images/postA4.jpeg'" alt="img">
                            </div>
                            <div class="categoryDescriptinDiv eventDetailsDiv">
                                <span class="postCount">{{item?.totalPost || 0}} {{item?.categoryName}}</span>
                                <span class="postCount view" (click)="categoriesRouting(item?.categoryName )">View all
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </ng-container>
                    <!-- category skeleton -->
                    <ng-template #categorySkeleton>
                        <div class="skeleton catPostDiv col-md-6 col-xl-4 col-lg-4 col-sm-2" *ngFor="let item of generateFake(6)">
                            <div class="item-wrap">
                                <div class="item-img">
                                    <ngx-skeleton-loader animation="progress" [theme]="skeletonImageTheme">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="categoryListArray?.length > 6" class="viewMoreDivAtBottom">
                    <Span class="viewMore" routerLink='/category/viewAllCategory'>View more categories <i
                            class="fa fa-arrow-right" aria-hidden="true"></i></Span>
                </div>
            </div>
            <!-- end categories section -->
        </div>
    </div>

    <!-- latest post section -->
    <div class="divAfterBanner">
        <div class="trendingPostMainDiv">
            <div class="postTypeHeading">
                <h2 class="heading">LATEST</h2>
            </div>
            <div class="trendingPost latestPostRow row">
                <!-- latest post -->
                <ng-container *ngIf="latestPostListArray.length">
                    <div *ngFor="let item of latestPostListArray" class="postDiv col-md-6 col-xl-4  col-lg-4 col-sm-2 ">
                        <div class="">
                            <div class="shareIconDiv">
                                <img class="profileImage" [src]="item?.userDetails?.profilePic ? item?.userDetails?.profilePic : 'assets/images/userIcon.png'" (click)="routerToOtherUserPrfile(item?.userDetails._id)" alt="img">
                                <p class="userName word-ellipsis pl-2" style=" margin: auto 0;">
                                    {{item?.userDetails?.fullName}}</p>
                                <i class="fa fa-share-alt icons" style="margin: auto 0 auto auto;font-size: 16px;" aria-hidden="true" data-toggle="modal" data-target="#shareModal"></i>
                            </div>
                            <div class="imageDiv">
                                <img *ngIf="item?.mediaType == 'IMAGE'" class="postImage latestImage cardcontainer" style="border-radius: 0px;" [src]="item?.imageLinks ? item?.imageLinks[0] : '../../../assets/images/postA4.jpeg'" alt="img" (click)="routerToOtherPostDescription(item)">
                                <video *ngIf="item?.mediaType == 'VIDEO'" class="postImageTop postImage videoBackground cardcontainer" (click)="routerToOtherPostDescription(item)" [src]="item?.videoLink" autoplay onloadedmetadata="this.muted = true" loop #videoplayer>
                                    <source type="video/mp4" />
                                    <track label="English" kind="captions">
                                    Browser not supported
                                </video>
                            </div>
                        </div>
                        <div class="postDescriptinDiv">
                            <p class="postDetails word-ellipsis postTitle">{{item?.description || '--'}}</p>
                            <p class="postTime">{{item?.createdAt | date:'medium'}}</p>
                        </div>
                    </div>
                </ng-container>
                <!-- latest skeleton -->
                <ng-template *ngIf="!isSkeleten">
                    <div class="skeleton postDiv col-md-6 col-xl-4  col-lg-4 col-sm-2" *ngFor="let item of generateFake(8)">
                        <div class="item-wrap">
                            <div class="item-img">
                                <ngx-skeleton-loader animation="progress" [theme]="skeletonImageTheme">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!--  if no post found  -->
                <div class="noPostVailableDiv " *ngIf="latestPostCount == 404" style="text-align: center;">
                    <div class="">
                        <img src="../../../assets/images/error404.png" alt="img" class="animate" style="width: 50%;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end latest post section -->
</div>


<!-- *********** share modal ************* -->
<div class="modal fade" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content share-to">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">Share your post</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="font-size: 20px;">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <a (click)="shareToWhatsapp('facebook')" class="fa fa-facebook share"></a>
                <a (click)="shareToWhatsapp('twitter')" class="fa fa-twitter share"></a>
                <a (click)="shareToWhatsapp('instagram')" class="fa fa-instagram share"></a>
                <a *ngIf="commonService.getDeviceType() == 'mobile'" (click)="shareToWhatsapp('whatsapp')" class="fa fa-whatsapp share" style="background: #15e676;"></a>
                <!-- <a (click)="shareToWhatsapp('linkdin')" class="fa fa-linkedin share"></a> -->
                <a (click)="shareToWhatsapp('google')" class="fa fa-google share"></a>
                <!-- <a (click)="shareToWhatsapp('skype')" class="fa fa-skype share"></a> -->
                <a (click)="shareToWhatsapp('copy')" class="fa fa-clone share" style="background: powderblue;"></a>
            </div>
            <!-- <div class="modal-footer">
                <input type="text" class="form-control" value="https://web-angular-stromestreet.mobiloitte.org">
                <button class="linkShareButton"><i class="fa fa-link" aria-hidden="true"></i></button>
            </div> -->
        </div>
    </div>
</div>
<!-- *********** end share modal ************* -->