<nav>
    <input type="checkbox" id="check" [(ngModel)]="check">
    <label for="check" class="checkbtn">
    <i class="fa fa-align-justify" ></i>
  </label>
    <label class="logo">
    <div class="logoImageNew">
      <img class="logoImage" src="../../../../assets/images/logoNewTransParent.png" alt="logo" routerLink="/homepage"
        routerlinkActive>
    </div>
  </label>
    <ul>
        <div class="ulContent">
            <div class="dropdown">
                <div class="input-group">
                    <input type="search" class="form-control rounded" placeholder="Search" [(ngModel)]="searchText" aria-label="Search" aria-describedby="search-addon" (keyup.enter)="onSearch()" maxlength="60" />

                    <i class="fa fa-search" aria-hidden="true" (click)="onSearch()"></i>
                </div>
            </div>
            <div class="dropdown" *ngIf="commonService.isLoggedIn()">
                <li routerLink="/user/upload-post"><span class="iconify" data-icon="mdi:ticket"></span><a class="buttonBackground" (click)="toogleNav()" routerLink="/user/upload-post" routerlinkActive>Create A Post</a></li>

            </div>
            <div class="dropdown ">
                <li class="dropbtn"><span class="iconify" data-icon="carbon:user-activity"></span>

                    <a class="dropdown-toggle"> Activity</a></li>
                <div class="dropdown-content">

                    <a class="dropdownItem" (click)="toogleNav()" routerLink="/activity/tranding" routerlinkActive>Trending</a>
                    <a class="dropdownItem" (click)="toogleNav()" routerLink="/activity/local">Local</a>
                    <a class="dropdownItem" (click)="toogleNav()" routerLink="/activity/following">Following</a>
                </div>
            </div>
            <div class="dropdown">

                <li class="dropbtn">
                    <span class="iconify" data-icon="ic:twotone-category"></span>

                    <a class="dropdown-toggle"> Category</a></li>
                <div class="dropdown-content">

                    <a class="dropdownItem" (click)="categoriesRouting(item?.categoryName ,item?._id)" (click)="toogleNav()" *ngFor="let item of categoryList"> {{item?.categoryName}}</a>

                </div>
            </div>
            <div *ngIf="authService.checkAuthToken()" class="dropdown" routerLink="/message">

                <li class="dropbtn">

                    <li> <span class="iconify mes" data-icon="bi:chat-square-dots"></span><a class="buttonBackground" (click)="toogleNav()" routerlinkActive>Messaging</a></li>



            </div>
            <div *ngIf="authService.checkAuthToken()" (click)="toogleNav()" class="dropdown" routerLink="/notification">

                <li><span class="iconify mes" data-icon="clarity:notification-solid"></span> <a class="buttonBackground" (click)="toogleNav()" routerlinkActive>Notifications</a></li>


            </div>

            <div *ngIf="!authService.checkAuthToken()" class="dropdown">
                <li><a class="buttonBackground" (click)="toogleNav()" routerLink="auth/login" routerlinkActive><span class="iconify new-login" data-icon="ls:login"></span> Login</a></li>
            </div>


            <div *ngIf="authService.checkAuthToken()" class="dropdown img-dataa">
                <li class="profileImage" (click)="toogleNav()"><img [src]="profileImageUrl || 'assets/images/profile-img.jpg'" (click)="profileCall()" alt=""></li>
            </div>

        </div>
    </ul>
</nav>