import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { CommonService } from "src/app/provider/common.service";
declare var $;
@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  @ViewChild("myDiv") myDiv: ElementRef;

  notificationArray: any = [];
  pageSize: any = 15;
  total: any;
  currentPage: any = 1;
  showClass: boolean = false;
  constructor(public commonService: CommonService,   private _bottomSheet: MatBottomSheet, private router: Router) { }

  openBottomSheet(_id): void {
    if (window.innerWidth > 426) {
      return;
    }
    this.id = _id;
    this._bottomSheet.open(BottomSheetOverviewExampleSheet);
  }
  eventListner() {
    var el = document.getElementById("demo");
    // var el = this.myDiv.nativeElement
    console.log(el);
    if (el) {
      el.addEventListener("long-press", () => {
        // this.openBottomSheet()
        // alert("ghjghjg");
      });
    }
  }
  ngOnInit(): void {
    this.getNotificationList();
    this.eventListner();
  }

  getNotificationList() {
    const data = {
      page: this.currentPage,
      limit: this.pageSize,
    };
    this.commonService.showSpinner();
    let apiReqUrl = `user/notificationList`;
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.notificationArray = res.result.docs;
        this.total = res.result.total;
        // this.eventListner()
        this.commonService.hideSpinner();
      } else {
        this.notificationArray = [];
        this.commonService.hideSpinner();
      }
    });
  }
  pageChanged(event) {
    this.currentPage = event;
    this.getNotificationList();
  }
  id: any;
  getId(_id) {
    this.id = _id;
    this.commonService.setNotificationId(_id);
    // this.notificationId.next(_id)
  }
  deleteNotifiaction() {
    this.commonService.showSpinner();
    let apiReqUrl = `user/deleteParticularNotificationUser?_id=${this.id}`;
    this.commonService.deleteApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.getNotificationList();
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    });
  }
  openPost(id){

  }
  clearAllNotification() {
    this.commonService.showSpinner();
    let apiReqUrl = `user/clearNotification`;
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.getNotificationList();
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    });
  }
  routerToOtherPostDescription(post) {
    var id
    if(post.notificationType== "LIKE_COMMENT" || post.notificationType== "COMMENT"){
id = post.commentId.postId
this.commonService.setParticularPostData(post.postId)
    
this.router.navigate(["/user/post-description"], { queryParams: { id: id, activityType: "userPostList" } })
    }
    else if(post.notificationType== "LIKE_POST"){
      id = post.postId._id
this.commonService.setParticularPostData(post.postId)
    
this.router.navigate(["/user/post-description"], { queryParams: { id: id, activityType: "userPostList" } })
    }
   else if(post.notificationType== "FOLLOW"){
    id = post.profileId._id
    // this.commonService.setParticularPostData(0)
        
    this.router.navigate(["/user/public-profile"], { queryParams: { id: id } })
   }
  }

}

@Component({
  selector: "bottom-sheet-overview-example-sheet",
  templateUrl: "bottom-sheet-overview-example-sheet.html",
})
export class BottomSheetOverviewExampleSheet {
  constructor(
    public commonService: CommonService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>,
   
  ) {
    this.commonService.notificationId.subscribe((res) => {
      // alert(res);
    });
  }
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  onLongPress() {
    // alert("called");
  }
  
}
