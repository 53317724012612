<!-- report Modal -->
<mat-card class="mat-elevation-z0 bdr mat-dialog-container">
    <div class="main">
        <div class="top-text">
            <mat-card-title class="sign-text">Report </mat-card-title>
        </div>
        <div class="input-field">
            <form [formGroup]="reportForm">
                <input type="text" class="user-name" placeholder="Commemnt" formControlName="comment">
                <textarea type="text" class="user-name" placeholder="Description" formControlName="description"
                    rows="5"></textarea>
                <!-- validations -->
                <div class="error" *ngIf="
              reportForm.get('description').hasError('required') &&
              (reportForm.get('description').touched ||
                reportForm.get('description').dirty)">
                    *Description is required.
                </div>
            </form>
        </div>
        <div class="down-button">
            <!-- button -->
            <button mat-button class="buttonGlobal" mat-dialog-close="true">
                Cancel
            </button>
            <button mat-button class="buttonGlobal" [mat-dialog-close]="!dialogCancel">Submit</button>
        </div>

    </div>
</mat-card>
<!-- <button (click)="reportDialog()">Report</button> -->