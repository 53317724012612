<div class="wrraper mainDiv">
    <div class="Heading text-center">
        <h2 class="headingTitle">Filter</h2>
    </div>
    <div class="subheading fadeInDown">
        <!-- <h3>Search by area:</h3> -->
        <div class="row bothDiv">
            <!-- =-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-==-=-=-=- search by area -==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- -->
            <div class="col-md-5 card">
                <div class="">
                    <h2 class="text-center">Search By Area</h2>
                    <div class="radioMainDiv text-center">
                        <div class="priceTag">
                            <p class="prieAmount"><label class="containerRadio nutral">Less than 02 miles
                                    <input type="radio" checked="checked" name="orderType" value="SELL">
                                    <span class="checkmark"></span>
                                </label></p>
                        </div>

                        <div class="priceTag">
                            <p class="prieAmount"><label class="containerRadio nutral">Less than 15 miles
                                    <input type="radio" checked="checked" name="orderType" value="SELL">
                                    <span class="checkmark"></span>
                                </label></p>
                        </div>
                        <div class="priceTag">
                            <p class="prieAmount"><label class="containerRadio nutral">Less than 30 miles
                                    <input type="radio" checked="checked" name="orderType" value="SELL">
                                    <span class="checkmark"></span>
                                </label></p>
                        </div>
                        <div class="priceTag">
                            <p class="prieAmount"><label class="containerRadio nutral">Less than 50 miles
                                    <input type="radio" checked="checked" name="orderType" value="SELL">
                                    <span class="checkmark"></span>
                                </label></p>
                        </div>
                        <div class="priceTag">
                            <p class="prieAmount"><label class="containerRadio nutral">More than 50 miles
                                    <input type="radio" checked="checked" name="orderType" value="SELL">
                                    <span class="checkmark"></span>
                                </label></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- =-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-==-=-=-=- search by category -==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- -->

            <div class="col-md-5">
                <div class="card">
                    <form action="">
                        <div class="subheading ">
                            <h2 class="text-center">Search by category</h2>
                            <div class="row checkBoxes">

                                <div class="col-md-6 check">
                                    <span>Weather</span>
                                    <section class="example-section">
                                        <mat-checkbox class="example-margin"></mat-checkbox>
                                    </section>
                                </div>
                                <div class="col-md-6 check">
                                    <span>Traffic</span>
                                    <section class="example-section">
                                        <mat-checkbox class="example-margin"></mat-checkbox>
                                    </section>
                                </div>
                                <div class="col-md-6 check">
                                    <span>Crime</span>
                                    <section class="example-section">
                                        <mat-checkbox class="example-margin"></mat-checkbox>
                                    </section>
                                </div>
                                <div class="col-md-6 check">
                                    <span>Event</span>
                                    <section class="example-section">
                                        <mat-checkbox class="example-margin"></mat-checkbox>
                                    </section>
                                </div>
                                <div class="col-md-6 check">
                                    <span>WTF </span>
                                    <section class="example-section">
                                        <mat-checkbox class="example-margin"></mat-checkbox>
                                    </section>
                                </div>
                                <div class="col-md-6 check">
                                    <span>Aliens</span>
                                    <section class="example-section">
                                        <mat-checkbox class="example-margin"></mat-checkbox>
                                    </section>
                                </div>
                            </div>

                        </div>
                        <div class="applybuttonDiv">
                            <button mat-raised-button class="globleBtn">Apply</button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>

</div>