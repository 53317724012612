import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})

export class HomepageComponent implements OnInit {
  messageForm: FormGroup;
  like: boolean = false;
  d = new Date();
  trandingPostArray: any = [];
  latestPostCount: any;
isSkeleten : boolean = true;

  constructor(public dialog: MatDialog, private router: Router, public commonService: CommonService) {
    window.scroll(0, 0)
  }

  ngOnInit(): void {
    this.messageForm = new FormGroup({
      "message": new FormControl('')
    })
    this.tradingActivity();
    this.getCategoryList();
    this.latestPost();
  }

  /**
   * get all trading post 
   * @param apiReqUrl contain request url with search query in param
   * @param apiReqData contain request body for search
   */

  likeCount: any = [];
  currentPage: number = 1
  limit: number = 8
  isLogged: any

  tradingActivity() {
    this.isLogged = this.commonService.isLoggedIn() ? 1 : 0
    let apiReqData = {
      // 'search': this.search,
      // 'categoryId': this.categoryId,
      // 'maxDistance': this.maxDistance
    }
    let apiReqUrl = `${'user/trendingPostList?page=' + this.currentPage + '&limit=' + this.limit}`
    // let apiReqUrl = `${'user/trendingPostList?&page' + this.currentPage + '&limit=' + this.limit
    // + (this.lat ? ('&lat=' + this.lat) : '') + (this.lng ? ('&lng=' + this.lng) : '')}`
    console.log(apiReqData);
    console.log(apiReqUrl);
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, this.isLogged).subscribe((res: any) => {
      console.log("res trading=> ", res)
      if (res.responseCode == 200) {
        this.trandingPostArray = res.result.docs
        this.commonService.setPostData(res.result.docs)
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage)
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  /**
  * category of events
  */
  categoryListArray: any = [];
  getCategoryList() {
    let url = "user/categoryListPostCount"
    this.commonService.showSpinner()
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      console.log(res)
      if (res["responseCode"] == 200) {
        this.categoryListArray = res["result"]["categoryResult"]
        console.log(this.categoryListArray);
        this.commonService.hideSpinner()
        // this.commonService.successToast(res["responseMessage"])
      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res["responseMessage"])
      }
    })
  }

  /**
   * get all trading post 
   * @param apiReqUrl contain request url with search query in param
   * @param apiReqData contain request body for search
   */
  latestPostListArray: any = [];
  latestPost() {
    this.isLogged = this.commonService.isLoggedIn() ? 1 : 0
    let apiReqData = {
      // 'search': this.search,
      // 'categoryId': this.categoryId,
      // 'maxDistance': this.maxDistance
    }
    // let apiReqUrl = `${'user/trendingPostList?&page' + this.currentPage + '&limit=' + this.limit}`
    let apiReqUrl = `${'user/latestPostList?&page' + this.currentPage + '&limit=' + this.limit}`
    console.log(apiReqData);
    console.log(apiReqUrl);
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, this.isLogged).subscribe((res: any) => {
      console.log("res trading=> ", res)
      if (res.responseCode == 200) {

        this.isSkeleten = false;
        console.log(res);
        this.latestPostListArray = res.result.docs
        this.commonService.setPostData(res.result.docs)

        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage)
      } else {
        this.isSkeleten = false;
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {
      console.log(err);
      if (err.status == 404) {
        this.isSkeleten = false;
        this.latestPostCount = err.status

      }
    })
  }


  // ----------  like dislike ------------ //
  likeDislike(tab) {
    if (tab == 'like') {
      this.like = false
    } else {
      this.like = true
    }
  }
  // logout() {
  //   const dialogRef = this.dialog.open(LogoutComponent, {
  //     width: "430px",
  //     height: "290px",
  //     autoFocus: false
  //   })
  // }

  // send() {
  //   console.log(this.messageForm.value.message);
  // }

  routerToOtherUserPrfile(id) {
    if (!this.commonService.isLoggedIn()) {
      this.commonService.infoToast("Please login before see this profile")
      return
    }
    // this.router.navigate(['/user/public-profile'])
    this.router.navigate(["/user/public-profile"], { queryParams: { id: id } });
  }

  goToPostdescription(post) {
    this.commonService.setParticularPostData(post)
    this.router.navigate(["/user/post-description"], { queryParams: { id: post._id, activityType: "trendingPostList" } });
  }

  // routing category waise 
  categoriesRouting(catType) {
    this.router.navigate(['/category/aliens'], {
      queryParams: {
        'categoryType': catType
      }
    })
  }

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=- trending post routing from home to post description =-=-=-=-=-=-=-=-=-=-=-=-=- 
  routerToOtherPostDescription(post) {
    this.commonService.setParticularPostData(post)
    // localStorage.setItem("particularPost", JSON.stringify(post))
    this.router.navigate(["/user/post-description"], { queryParams: { id: post._id, activityType: "trendingPostList" } });
  }

  navigateToCategory() {
    this.router.navigate(['/category/aliens'])
  }

  current_Url: any = window.location.href
  shareToWhatsapp(socialMedia) {
    var mail = document.createElement("a");
    if (socialMedia == 'whatsapp') {
      mail.href = `whatsapp://send?text=${this.current_Url}`
    }
    else if (socialMedia == 'facebook') {
      mail.href = `https://www.facebook.com/sharer/sharer.php?u=${this.current_Url}`
    }
    else if (socialMedia == 'twitter') {
      mail.href = "https://twitter.com/share?url=" + encodeURIComponent(this.current_Url) + "&text=" + document.title
    }
    else if (socialMedia == 'instagram') {
      mail.href = `https://www.instagram.com/?url=${this.current_Url}`
    }
    else if (socialMedia == 'copy') {
      this.commonService.successToast('Sharing link copy successfully.')
      navigator.clipboard.writeText(this.current_Url)
    }
    else if (socialMedia == 'google') {
      mail.href = `mailto:expample@gmail.com?Subject=${document.title}&body=${this.current_Url}`
    }

    mail.target = "_blank"
    mail.click()
    // this.service.toasterSucc("Replied Successfully")
  }


  /**
   * Skelton for tranding
   * @param count 
   */
  // skeleton ui configuration
  skeletonImageTheme = { height: '200px' }
  skeletonTextTheme = { height: '30px' }

  // Generate Fake Object Array
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }


}
