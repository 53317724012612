import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public router: Router,public commonService : CommonService) { }

  ngOnInit(): void {
    this.getSocialLinks()
  }
  routeToAbout() {
    this.router.navigate(['/static-content/about-us'])
  }
  routeToContact() {
    this.router.navigate(['/static-content/contact-us'])
  }
  routeToFAQ() {
    this.router.navigate(['/static-content/faq'])
  }
  routeToTerms() {
    this.router.navigate(['/static-content/terms-of-service'])
  }
  routeToPrivacy() {
    this.router.navigate(['/static-content/privacy-policy'])

  }
  socialLinksList : any  = []
  getSocialLinks(){
    let url = "admin/listSocialLink"
    this.commonService.getApi(url,0).subscribe((res)=>{
      if(res["responseCode"] == 200){
        this.socialLinksList = res["result"]
      }
    })
  }

}
