<!-- Footer -->
<footer class="page-footer font-small unique-color-dark">
    <!-- Footer Links -->
    <div class=" text-center text-md-left mt-5">
        <!-- Grid row -->
        <div class="row mt-3" style="margin-right: 0px">
            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <!-- Content -->
                <!-- <p class="text-uppercase font-weight-bold">Storm Street</p> -->
                <img class="footerLogo  mb-2" src="../../../../assets/images/logoNewTransParent.png" alt="logo" routerLink="/homepage">
                <!-- <p> This option will redirect the user to the add post screen, where the user will get the option to add the post from the gallery or to open the camera.</p> -->
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <!-- Links -->
                <p class="footer-static-content" (click)="routeToAbout()">About Us</p>
                <!-- <p class="footer-static-content"><a (click)="routeToAbout()">About Us</a></p> -->
                <p class="footer-static-content"><a (click)="routeToPrivacy()">Privacy Policy</a></p>
                <p class="footer-static-content"> <a (click)="routeToContact()">Contact Us</a> </p>
                <p class="footer-static-content"> <a (click)="routeToFAQ()">FAQ's</a> </p>
                <p class="footer-static-content"> <a (click)="routeToTerms()">Terms & Conditions</a> </p>
            </div>
            <!-- Grid column -->


            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <!-- Links -->
                <p class="text-uppercase font-weight-bold share-modal-icons">Community</p>

                <div class="modal-body">

                    <a *ngIf="socialLinksList[0]?.socialName == 'Facebook'" [href]="socialLinksList[0]?.socialLink" target="_blank" class="fa fa-facebook share"></a>
                    <a *ngIf="socialLinksList[1]?.socialName == 'Twitter'" [href]="socialLinksList[1]?.socialLink" target="_blank" class="fa fa-twitter share"></a>
                    <!-- <a *ngIf="socialLinksList[2]?.s/ocialName == 'Google'" [href]="socialLinksList[2]?.socialLink" target="_blank" class="fa fa-google share"></a> -->
                    <!-- <a *ngIf="socialLinksList[3]?.socialName == 'Linkedin'" [href]="socialLinksList[3]?.socialLink" target="_blank" class="fa fa-linkedin share"></a> -->
                    <a *ngIf="socialLinksList[2]?.socialName == 'Instagram'" [href]="socialLinksList[2]?.socialLink" target="_blank" class="fa fa-instagram share"></a>
                    <!-- <a *ngIf="socialLinksList[5]?.socialName == 'Skype'" [href]="socialLinksList[5]?.socialLink" target="_blank" class="fa fa-skype share"></a> -->

                </div>

            </div>

            <!-- Grid column -->
        </div>
        <!-- Grid row -->
    </div>
    <!-- Footer Links -->
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">Copyright Storm Street & Outsider Labs 2022
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer -->