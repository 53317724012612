import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from "./material/material.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './pages/sharing/header/header.component';
import { FooterComponent } from './pages/sharing/footer/footer.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
// import { AuthModule } from './pages/auth/auth.module'
// import { StaticContentModule } from './pages/static-content/static-content.module'
// import { CategoryModule } from './pages/category/category.module'
// import { UserProfileModule } from './pages/user-profile/user-profile.module';
import { LogoutComponent } from './pages/modal/logout/logout.component';
import { MessageComponent } from './pages/message/message.component';
import { ChatListComponent } from './pages/chat-list/chat-list.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
// import { ActivityModule } from './pages/activity/activity.module';
import { BottomSheetOverviewExampleSheet, NotificationComponent } from './pages/notification/notification.component';
import { FilterComponent } from './pages/filter/filter.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { CommonService } from './provider/common.service';
import {NgxPaginationModule} from 'ngx-pagination';
import { ReportComponent } from './pages/modal/report/report.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
// import { ClipboardModule } from 'ngx-clipboard';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';


const config: SocketIoConfig = {
  url: 'https://node-stromestreet.mobiloitte.com',
  // url: 'http://172.16.2.19:3027',
  // url: 'https://4654-182-71-75-106.ngrok.io',
  options: {},
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppComponent,
    HomepageComponent,
    LogoutComponent,
    MessageComponent,
    ChatListComponent,
    NotificationComponent,
    FilterComponent,
    ReportComponent,
    BottomSheetOverviewExampleSheet,
    PageNotFoundComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      preventDuplicates: true
    }),
    // AuthModule,
    // CategoryModule,
    // StaticContentModule,
    // UserProfileModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    // ActivityModule,
    NgxPaginationModule,
    SocketIoModule.forRoot(config),
    HttpClientModule,
    NgxSkeletonLoaderModule,
    SocialLoginModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, CommonService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              // '882368902287-1i3a97c7ma6bfvv8e1secnp4vb7e283n.apps.googleusercontent.com'
              // '882368902287-8r16pmef0r8vjv7reuvpie5dcle03dgl.apps.googleusercontent.com'
              // 'AIzaSyDUtjra7eoTs84VblvzS9_f-XgzJKUTvwE'
              '975526364107-prduir8nv6r6p6k3qjhnfrdaj769o44k.apps.googleusercontent.com'
              // '200566794515-nbl5f95r8olts9uvju33iiatatkvonri.apps.googleusercontent.com'
              // '200566794515-cbocs1dupgo1q7ruq9m3ijl57vmerocl.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('561602290896109')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
