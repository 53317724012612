<div class="wrapper fadeInDown">
    <div class="formContent">
        <!-- Icon -->
        <div class="fadeIn first d-flex justify-content-between">
            <span><strong>
                    <h2 class="headingTitle">Notifications

                    </h2>
                </strong>


            </span>
            <div class="dropdown">
                <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </button>
                <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item " data-toggle="modal" data-target="#clearNotificationModal">Clear all
                        notification</a>

                </div>
            </div>
        </div>
        <div *ngFor="let item of notificationArray| paginate: {  itemsPerPage: pageSize,currentPage: currentPage,totalItems: total };let i = index" class="row mb-2" style="cursor: pointer;" [ngClass]="i%2==0 ? 'blurBackground':'blurBackground2'" (click)="openBottomSheet(item?._id);openPost(item?.notificationBy?._id)">
            <div class="col-md-8 col-xs-8" style="text-align: left;display: flex;" (click)="routerToOtherPostDescription(item)">
                <img style="height: 50px;width: 50px;border-radius: 50%;" [src]="item?.notificationBy?.profilePic || 'assets/images/profile-img.jpg'" alt="">
                <span>
                    <p class="bold" style="margin: 0px 10px -10px;">{{item?.notificationBy?.userName}}</p>
                 <mat-card-subtitle  >{{item?.body }}</mat-card-subtitle>
                 <!-- <mat-card-subtitle *ngIf="item?.notificationType == 'LIKE_POST'">{{item?.notificationBy?.userName}} has liked on your post</mat-card-subtitle> -->
             </span>
                <!-- <mat-card-subtitle *ngIf="item?.notificationType ? 'COMMENT' : 'LIKE_POST'">{{item?.body ? item?.body :`${item?.notificationBy?.userName} is liked on your post` }}</mat-card-subtitle> -->

            </div>
            <div class="col-md-3 col-xs-3 not-time" style="margin-top: 10px;">{{item.createdAt | date : 'medium'}}</div>
            <div class="col-md-1 col-xs-1">
                <div class="dropdown">
                    <button class="btn  btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu " [ngClass]="showClass ? 'showDropDown':''" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item " data-toggle="modal" data-target="#deleteModal" (click)="getId(item?._id)">Delete</a>

                    </div>
                </div>
            </div>
            <!-- <hr> -->
        </div>

        <mat-card-subtitle *ngIf="!notificationArray.length">
            <h4 class="text-align-center W-100">No Notification Found!</h4>
        </mat-card-subtitle>
        <div *ngIf="total > 10" class="row justify-content-end W-100">
            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
        </div>



    </div>
</div>
<!-- delete notification modal -->
<div class="modal fade " id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <form class="change_password" style="width: 100%;">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h2 class="modal-title d-inline-block">Delete Notification</h2>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to delete this notification?</p>
                                <div class="modal-btn-box">
                                    <div class="row justify-content-around noti">
                                        <div class="new-one">
                                            <div class="col-md-6 col-sm-4">
                                                <button type="button" class="btn btn-secondary" style="width: 7em;" data-dismiss="modal">No</button>
                                            </div>
                                            <div class="col-md-6 col-sm-4">
                                                <button type="submit" class="btn btn-primary  " style="width: 7em;" data-dismiss="modal" (click)="deleteNotifiaction()">Yes</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- clear all notification modal -->
<div class="modal fade " id="clearNotificationModal" tabindex="-1" role="dialog" aria-labelledby="clearNotificationModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <form class="change_password" style="width: 100%;">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h2 class="modal-title d-inline-block">Clear All Notification</h2>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to clear all notification?</p>
                                <div class="modal-btn-box">
                                    <div class="row justify-content-around">
                                        <div class="col-md-6">
                                            <button type="submit" class="btn btn-primary  " style="width: 7em;" data-dismiss="modal" (click)="clearAllNotification()">Yes</button>
                                        </div>
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-secondary" style="width: 7em;" data-dismiss="modal">No</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>