import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/provider/auth.service';
// import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/provider/common.service';
import { EventEmitterService } from 'src/app/provider/event-emitter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profileImageUrl: any //profile image url 
  categoryList: any = [];
  searchText : any 
  constructor(private router: Router, public authService: AuthService, public commonService: CommonService,public eventEmitterService : EventEmitterService) {
    
   }
   
  ngOnInit(): void {
    this.getCategoryList() // get all category list
    this.authService.loggedInData.subscribe((res: any) => {
      console.log(res)
      this.profileImageUrl = res
    })
    if (this.authService.checkAuthToken()) {
      this.getProfileDetails()
    }
    // this.getCategoryList()
  }
  check : boolean = false
  toogleNav(){
    
    this.check = this.check ? false : true 
   
  }
 

  /**
   * @private subscribe subject function user app component and next use in edit-profile component 
   */
  // ngAfterContentChecked(): void {
  //   this.app.showImage.subscribe((res: any) => {
  //     this.imageUrl = res
  //   })
  // }

  /**
   * @private  Navigate to user profile 
   */
  profileCall() {
    this.router.navigate(['/user/profile'])
  }

  categoriesRouting(catType , id) {
    this.router.navigate(['/category/aliens'], { queryParams: { 
      'categoryType': catType ,
      'catId' :id
    } })
  }
  getCategoryList() {
    let url = "user/categoryList"
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res) => {
      if (res["responseCode"] == 200) {
        this.categoryList = res["result"]["categoryResult"]
        this.commonService.hideSpinner()
        // this.commonService.successToast(res["responseMessage"])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res["responseMessage"])
      }
    })
  }
  // logout
  // logout() {
  //   this.commonService.logout()
  // }

  /**
   * @private Get APi of User Edit profile details
   */
  getProfileDetails() {
    this.commonService.getApi('user/getProfile', 1).subscribe(res => {
      if (res['responseCode'] == 200) {
        // let imageUrl = res['result']['userResult']['profilePic']
        // console.log(imageUrl)
        this.authService.loggedInData.next(res['result']['userResult']['profilePic'])
      }
    })
  }

  onSearch(){
    localStorage.setItem("searchText",this.searchText)
    this.router.navigate(['/activity/tranding'])
    this.router.navigate(['/activity/search-result'])
    this.eventEmitterService.onFirstComponentButtonClick();
  }
  

}
