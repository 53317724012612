import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { CommonService } from "src/app/provider/common.service";
import { Socket } from "ngx-socket-io";
import { ChatService } from "src/app/provider/chat.service";
import { AuthService } from "src/app/provider/auth.service";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnInit, OnDestroy {
  @ViewChild("contentArea") private contentArea: ElementRef;
  messageForm: FormGroup;
  userName = [
    { name: "King  Waliullah" },
    { name: "Somya " },
    { name: "Tanveer " },
    { name: "Arvind sharma" },
    { name: "Ankita Kumari" },
    { name: "Sushmita" },
    { name: "Mohit" },
    { name: "Satyam" },
    { name: "King  Waliullah" },
    { name: "Somya " },
    { name: "Tanveer " },
    { name: "Arvind sharma" },
    { name: "Ankita Kumari" },
    { name: "Sushmita" },
    { name: "Mohit" },
    { name: "Satyam" },
  ];
  // constructor() { }

  // ngOnInit(): void {
  //   this.messageForm = new FormGroup({
  //     "message" : new FormControl('')
  //   })
  // }
  send() {
    console.log(this.messageForm.value.message);
  }
  contactList: any = [];
  // senderId: any;
  // adminId: any;
  myId: any;
  particularChat: any = [];
  chatId: any;
  chatUserId: any;
  massageChat: boolean = true;
  message: any;
  receiverId: any;
  userDetail: any;
  sendNewMessage = "";
  index = 0;
  newMessageData: any;
  newMessage: any = "";
  messageList: any = [];
  chatData: any = [];
  recentChatContactList: any = [];
  userTapped: boolean = false;
  profileImageUrl: any;

  constructor(
    public chatService: ChatService,
    public socket: Socket,
    public commonService: CommonService,
    public authService: AuthService
  ) { }

  async ngOnInit() {
    // allChatList: any = [];
    this.socket.connect();
    this.myId = localStorage.getItem("myId");
    this.profileImageUrl = localStorage.getItem("myProfile");
    // this.authService.loggedInData.subscribe((res: any) => {
    //   console.log(res)
    //   this.profileImageUrl = res
    // })

    // this.adminId = '617815e82a3ecb5f876bbac9'
    if (!this.myId || !this.profileImageUrl) {
      console.log("this.myId", this.myId);
      this.getProfileDetails();
    } else {
      this.showOnline();
    }
    //  this.showContactList();
    // this.getChatHistory(true)
    this.receiveMessage();
    // this.recentChatList()
    this.followingList();
  }

  // scroll chat of buyer and seller container
  scrollToBottom() {
    setTimeout(() => {
      try {
        this.contentArea.nativeElement.scrollTop =
          this.contentArea.nativeElement.scrollHeight;
      } catch (err) { }
    }, 10);
  }
  /**
   * @private Get APi of User Edit profile details
   */
  getProfileDetails() {
    this.commonService.getApi("user/getProfile", 1).subscribe((res) => {
      if (res["responseCode"] == 200) {
        this.myId = res["result"]["userResult"]["_id"];
        // this.authService.loggedInData.next(res['result']['userResult']['profilePic'])
        this.profileImageUrl = res["result"]["userResult"]["profilePic"];
        this.showOnline();
      }
    });
  }

  // get contact list of user
  // showContactList() {
  //   let url = 'admin/contactList';
  //   this.commonService.showSpinner();
  //   this.commonService.getApi(url, 1).subscribe((res: any) => {
  //     console.log('contactList =>', res);
  //     if (res.responseCode == 200) {
  //       this.contactList = res.result;

  //       this.commonService.hideSpinner();
  //       this.commonService.successToast(res.responseMessage);
  //       this.showOnline();
  //       // this.chatHistory(true);
  //       this.receiveMessage();
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.responseMessage);
  //     }
  //   },
  //     (err) => {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(err);
  //     }
  //   );
  // }

  // make admin online
  showOnline() {
    let obj = {
      userId: this.myId,
      // userId: '617815e82a3ecb5f876bbac9'
    };
    console.log("online user->", obj);
    this.socket.emit("onlineUser", obj);
    this.recentChatContact(true); //
  }

  // get chat history of admin and user
  recentChatContact(id) {
    this.particularChat = [];
    // this.allChatList = [];
    // this.receiverId = id

    let chatData = {
      senderId: this.myId,
      // "receiverId": this.receiverId
    };
    // let chatData = {
    //   "senderId": "617815e82a3ecb5f876bbac9",
    //   "receiverId": "61779509d0f5990a0888791e"
    // }
    console.log("Req,data", chatData);
    this.socket.emit("chatHistory", chatData);
    this.getRecentChatContact(true);
  }

  getRecentChatContact(id) {
    console.log("get chat history");
    this.socket.fromEvent("chatHistory").subscribe(
      (response: any) => {
        console.log("getChatHistory =>", response);
        if (response["response_code"] == 200) {
          if (id == true) {
            this.recentChatContactList = response["result"];
            this.recentChatContactList = this.recentChatContactList.filter(
              (res) => {
                if (res?.receiverId && res?.senderId) {
                  return res.receiverId._id != res.senderId._id;
                }
              }

              // x => x.receiverId._id != x.senderId._id
            );
            this.tempArray = this.recentChatContactList;
            console.log(this.recentChatContactList);
            // console.log(this.particularChat)
            // this.scrollToBottom()
          }
        } else {
          this.recentChatContactList = [];
          // this.allChatList = [];
        }
      },
      (err) => {
        this.commonService.errorToast("Something went wrong.");
      }
    );
  }

  // -------------------- receive new message ------------------- //
  receiveMessage() {
    console.log("receive message chat");
    this.chatService.getMessage().subscribe((message: any) => {
      console.log("receive message222 ==>", message);
      if (message["response_code"] == 200) {
        let lastMessage = message["result"]["messages"][0];
        console.log(lastMessage);
        if (this.userTapped) {
          // this.recentChatContactList.forEach(element => {
          console.log(this.chatId, lastMessage.chatId);
          if (this.chatId == lastMessage.chatId) {
            console.log("ifffffff");
            console.log(this.chatId, lastMessage.chatId);
            this.particularChat.messages.push(lastMessage);
          }
          //  else if (this.chatId == 'undefined') {
          //   console.log(this.chatId)
          //   console.log("l:206-> ", this.message.chatHistory[0]._id)
          //   console.log("l:207->", lastMessage.chatId)
          //   if (this.message.chatHistory[0]._id = lastMessage.chatId) {
          //     console.log("ifff matched")
          //     this.particularChat.messages.push(lastMessage);
          //   }
          // }
          else {
            console.log("else 215", this.userTapped);
            console.log(this.chatId);
            console.log("l:206-> ", message.chatHistory[0]._id);
            console.log("l:207->", lastMessage.chatId);
            // let messages = []
            if ((message.chatHistory[0]._id = lastMessage.chatId)) {
              console.log("ifff matched");
              // this.particularChat.push(messages)
              // this.i = 0
              // this.array_temp.push(lastMessage)
              // this.particularChat.messages = this.array_temp
              // this.i++
            }
          }
          // });
        } else if ((message.chatHistory[0]._id = lastMessage.chatId)) {
          this.array_temp.push(lastMessage);
          this.particularChat.messages = this.array_temp;
          console.log("else", this.userTapped);
        }
        // this.recentChatContact(this.myId)
        // this.recentChatContactList.push()
        // console.log("recent chat -->",this.recentChatContactList)

        // refresh recent chat list
        // console.log(message.chatHistory)
        // this.recentChatContactList = message.chatHistory
        // console.log("mmmm->", this.recentChatContactList)
        this.commonService.incommingMeassageAudio();
        console.log(
          "chat history from oneToOneChat event->",
          message.chatHistory
        );
        this.recentChatContactList = message.chatHistory;
        console.log("mmmm->", this.recentChatContactList);
        this.pushLastMessageInRecentChatList(message["result"]);
        console.log("receive last message", this.particularChat);
        this.scrollToBottom();
      }
    });
  }
  array_temp = [];
  i = 0;
  pushLastMessageInRecentChatList(message) {
    // console.log("recent chat -->", this.recentChatContactList)
    if (this.recentChatContactList.length) {
      this.recentChatContactList.forEach((element) => {
        // console.log(element)
        if (!element._id) {
          element.messages.push(message["messages"][0]);
        }
        if (element._id == message._id) {
          element.messages.push(message["messages"][0]);
        }
      });
    }
  }
  // -------------------- receive new message ------------------- //
  recentChatList() {
    console.log("recent receive message chat");
    this.chatService.getRecentChatList().subscribe((message: any) => {
      console.log("receive message234 ==>", message);
      if (message["response_code"] == 200) {
        //   let lastMessage = message['result']['messages'][0]
        //   console.log(lastMessage)
        //   this.particularChat.messages.push(lastMessage);
        //   console.log("receive last message", this.particularChat);
        //   this.scrollToBottom()
      }
    });
  }

  //----------------- view chat --------------------//
  viewChat() {
    if (this.message == "") {
      return;
    } else {
      let data = {
        message: this.newMessage,
      };
    }
  }

  //-------------------------- send message ----------------------------//
  sendMessage() {
    console.log("send clicked", this.receiverId);
    if (this.newMessage == "") {
      return;
    } else if (this.receiverId == undefined) {
      return this.commonService.infoToast("Please select user.");
    } else {
      this.newMessageData = {
        senderId: this.myId,
        receiverId: this.receiverId,
        message: this.newMessage,
      };
      // this.newMessageData = {
      //   "senderId": "617815e82a3ecb5f876bbac9",
      //   "receiverId": "61779509d0f5990a0888791e",
      //   "message": "hii"
      // }
      console.log(this.newMessageData);
      this.chatService.sendMessage(this.newMessageData);
      this.newMessage = "";
      // this.getRecentChatContact(this.myId);
    }
  }

  // clear chat between admin and user
  clearChat() {
    let chatData = {
      senderId: this.myId,
      receiverId: this.receiverId,
    };
    console.log("clear chat", chatData);
    this.particularChat.messages = [];
    this.chatService.clearChat(chatData);
  }

  // // Get chat Id
  // individualChatId(chatId: any, userId: any) {
  //   this.showOnline();

  //   // alert('ddddd')
  //   this.chatId = chatId;
  //   this.chatUserId = userId;
  //   console.log('particular chat id', chatId);

  //   this.particularChatHistory('1');
  // }

  // async particularChatHistory(val: any) {
  //   this.showOnline();

  //   console.log('val????', JSON.stringify(val));
  //   this.massageChat = true;
  //   this.message = '';

  //   if (this.chatId == '') {
  //     console.log('no chat IdL');
  //     return;
  //   }

  //   let chatData = {
  //     chatUserType: 'ADMIN',

  //     adminChat: true,
  //     // chatId: this.chatId ? this.chatId : ''
  //     chatId: this.chatId ? this.chatId : '',
  //   };
  //   console.log('particularChatHistory to show 1????', this.chatId);
  //   this.socket.emit('particularChatHistory', chatData);
  //   await this.socket.fromEvent('particularChatHistory').subscribe(
  //     (response: any) => {
  //       if (response['responseCode'] == 200) {
  //         console.log(
  //           'in history chatasdasasdasasAAAAAA',
  //           response['chatList']
  //         );
  //         // console.log("i am userthis.chatUserId,val)
  //         if (val == '1') {
  //           console.log('145');
  //           this.particularChat = response['result'][0]['message'];
  //           this.allChatList = response['chatList'];

  //           // }
  //           // else {
  //           //   console.log("151")
  //           //   this.allChatList = response["chatList"]
  //         }
  //       } else {
  //         this.particularChat = [];
  //         this.allChatList = [];
  //         // this.mainservice.toastErr(response["responseMessage"])
  //       }
  //     },
  //     (err) => {
  //       this.mainservice.errorToast('Something went wrong');
  //     }
  //   );
  // }

  // open close section of new chat
  openGroup() {
    document.getElementById("new-group-id").style.width = "100%";
  }

  closeGroup() {
    document.getElementById("new-group-id").style.width = "0";
  }

  followinglistData: any = [];
  followingList() {
    // $('#following').modal('show');
    this.commonService.getApi("user/followingList", 1).subscribe(
      (res) => {
        console.log(res);
        if (res["responseCode"] == 200) {
          this.followinglistData = res["result"]["docs"];
          // for (let item of this.followinglistData) {
          //   this.folowingListOption.push(true)
          // }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // chatWithNewUser(id) {
  //   console.log("chat with new user");
  //   this.closeGroup()
  //   // this.recentChatContact(id);
  //   this.viewChatHistory(id)
  // }

  chatWithNewUser(item) {
    var userNotFound = false;
    var temp = false;
    console.log("chat with new user");
    console.log(item);
    this.closeGroup();
    // this.recentChatContact(id);
    this.viewChatHistory(item.userId._id);

    if (this.recentChatContactList.length) {
      console.log("dhsfjjsjdjsjj", this.recentChatContactList.length);
      for (let data of this.recentChatContactList) {
        if (
          data?.receiverId?._id == item?.userId?._id ||
          data?.senderId?._id == item?.userId?._id
        ) {
          return;
        }
      }
    } else {
      this.recentChatContactList.push({
        receiverId: {
          fullName: item.userId.fullName,
          profilePic: item.userId.profilePic,
          userName: item.userId.userName,
          _id: item.userId._id,
        },
        senderId: {
          fullName: "Stromstreet",
          profilePic: this.profileImageUrl,
          userName: "strom@1",
          _id: this.myId,
        },
      });
    }

    //
    if (item.userId._id) {
      if (!temp) {
        this.recentChatContactList.forEach((element) => {
          console.log(element.receiverId._id, "dhnsfgkjm", item.userId._id);
          console.log(element.senderId._id, "dhnsfgkjm", item.userId._id);
          if (
            element.receiverId._id != item.userId._id &&
            element.senderId._id != item.userId._id
          ) {
            userNotFound = true;
          } else {
            temp = true;
            userNotFound = false;
            return;
          }
        });
      }
      if (userNotFound) {
        this.recentChatContactList.push({
          receiverId: {
            fullName: item.userId.fullName,
            profilePic: item.userId.profilePic,
            userName: item.userId.userName,
            _id: item.userId._id,
          },
          senderId: {
            fullName: "Stromstreet",
            profilePic: this.profileImageUrl,
            userName: "strom@1",
            _id: this.myId,
          },
        });
        console.log(userNotFound);
        console.log(this.recentChatContactList);
        //
      }
    }
    // push this user on contact list if not their
    // if(item.userId._id == this.recentChatContactList.receiverId._id ){
    //   if()
    // }
    // (this.recentChatContactList.receiverId._id == this.recentChatContactList.senderId._id) ?(this.recentChatContactList.push({
    //   'receiverId': {
    //     fullName: item.userId.fullName,
    //     profilePic: item.userId.profilePic,
    //     userName: item.userId.userName,
    //     _id: item.userId._id
    //   },
    //   'senderId': {
    //     fullName: "Stromstreet",
    //     profilePic: "https://res.cloudinary.com/mobiloitte-testing/image/upload/v1635837242/tnred0iehtnd6jeej5ir.jpg",
    //     userName: "strom@1",
    //     _id: this.myId
    //   }
    // })):return
    // this.userTappedShowOnHeader(item.userId.profilePic, item.userId.fullName)
    // console.log(this.recentChatContactList)
  }

  // get recent chat
  // recentChatContact(){

  // }

  // get chat history of admin and user
  viewChatHistory(id) {
    this.particularChat = [];
    // this.allChatList = [];
    this.receiverId = id;

    let chatData = {
      senderId: this.myId,
      receiverId: this.receiverId,
    };
    // let chatData = {
    //   "senderId": "617815e82a3ecb5f876bbac9",
    //   "receiverId": "61779509d0f5990a0888791e"
    // }
    console.log("Req data view chat", chatData);
    this.socket.emit("viewChat", chatData);
    this.getViewChatHistory(true);
    this.userTapped = true;
  }

  getViewChatHistory(id) {
    console.log("get view chat history");
    this.socket.fromEvent("viewChat").subscribe(
      (response: any) => {
        console.log("get view chat history =>", response);
        if (response["response_code"] == 200) {
          if (id == true) {
            this.particularChat = response["result"];
            // console.log(this.particularChat)
            this.scrollToBottom();
          }
        } else {
          this.particularChat = [];
          // this.allChatList = [];
        }
      },
      (err) => {
        this.commonService.errorToast("Something went wrong.");
      }
    );
  }

  profilePic: any;
  tappedUserName: any;
  // show user on chat header
  userTappedShowOnHeader(profilePic, tappedUserName, chatId) {
    console.log(profilePic, tappedUserName, chatId);
    console.log(chatId);
    this.profilePic = profilePic;
    this.tappedUserName = tappedUserName;
    if (chatId == undefined) {
      console.log("ifffff ", chatId);
    } else {
      console.log("else ", chatId);
      this.chatId = chatId.messages[0].chatId;
    }

    console.log(this.chatId);
    console.log(chatId);

    // this.chatId = (chatId == undefined) ? '' : chatId.messages[0].chatId
  }

  searchRecentChat: any;
  tempArray: any = [];
  // search existing user
  serachRecentChatContact() {
    var searchRecentChat = String(this.searchRecentChat).toLowerCase();
    console.log(this.tempArray);

    this.recentChatContactList = this.recentChatContactList.filter((res) => {
      let senderName = String(res?.senderId?.fullName).toLowerCase();
      let recieverName = String(res?.receiverId?.fullName).toLowerCase();

      return (
        senderName.includes(searchRecentChat) ||
        recieverName.includes(searchRecentChat)
      );
    });
    console.log(
      "-=-=-=-=search result =-=-=-=-=-=-=",
      this.recentChatContactList
    );
  }
  backSpaceEvent(event) {
    if (event.keyCode === 8) {
      this.recentChatContactList = this.tempArray;
      this.serachRecentChatContact();
    }
  }

  ngOnDestroy() {
    let obj = {
      userId: this.myId,
      // userId: '617815e82a3ecb5f876bbac9'
    };
    console.log("onflineser->", obj);
    this.socket.emit("offlineUser", obj);
    this.chatService.disconnect();
  }
}
