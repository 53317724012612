import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  reportForm: FormGroup;
  dialogCancel: boolean = true;
  showSecondDialog: boolean = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public service: CommonService
  ) { }

  ngOnInit(): void {
    this.reportForm = new FormGroup({
      comment: new FormControl(""),
      description: new FormControl("", [Validators.required]),
    });

  }

}
