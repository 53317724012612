import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { FooterComponent } from './pages/sharing/footer/footer.component';
// import { HeaderComponent } from './pages/sharing/header/header.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
// import { AuthRoutingModule } from './pages/auth/auth-routing.module';
// import { CategoryRoutingModule } from './pages/category/category-routing.module';
// import { UserProfileRoutingModule } from './pages/user-profile/user-profile-routing.module';
// import { ActivityRoutingModule } from './pages/activity/activity-routing.module';
import { ChatListComponent } from './pages/chat-list/chat-list.component';
import { LogoutComponent } from './pages/modal/logout/logout.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { MessageComponent } from './pages/message/message.component';
import { FilterComponent } from './pages/filter/filter.component';
// import { StaticContentRoutingModule } from './pages/static-content/static-content-routing.module';
// import { AuthModule } from './pages/auth/auth.module';
import { AuthGuard } from './guard/auth.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
// import { LoginComponent } from './pages/auth/login/login.component';
// import { AuthModule } from './pages/auth/auth.module';

const routes: Routes = [
  { path: "", redirectTo: "homepage", pathMatch: "full" },
  // { path: '**', pathMatch: 'full', 
  //       component: PageNotFoundComponent },
  { path: "homepage", component: HomepageComponent },
  // { path: 'auth', component: AuthModule, loadChildren: () => import("./pages/auth/auth.module").then(m => m.AuthModule) },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canActivate: [AuthGuard] },
  { path: 'activity', loadChildren: () => import('./pages/activity/activity.module').then(m => m.ActivityModule) },
  { path: 'category', loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule) },
  { path: 'static-content', loadChildren: () => import('./pages/static-content/static-content.module').then(m => m.StaticContentModule) },
  { path: 'user', loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule) },
  // { path: 'auth', component: Auth, loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule) }
  // { path: "header", component: HeaderComponent },
  // { path: "footer", component: FooterComponent },
  { path: "chat-list", component: ChatListComponent },
  { path: "logout", component: LogoutComponent },
  { path: "notification", component: NotificationComponent },
  { path: "message", component: MessageComponent },
  { path: "filter", component: FilterComponent },
  { path : '**',component : PageNotFoundComponent}
]

@NgModule({
  imports: [RouterModule.forRoot(routes),
    // AuthRoutingModule,
    // CategoryRoutingModule,
    // UserProfileRoutingModule,
    // ActivityRoutingModule,
    // StaticContentRoutingModule
  ],
  exports: [RouterModule,
  ],

})
export class AppRoutingModule {

}
