<div class="wrraper">
  <div class="header text-center">
    <h1>Messages</h1>
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <span matPrefix> </span>
        <input type="tel" matInput placeholder="Search" name="search">
        <button matSuffix mat-button>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <br />
    </form>
  </div>


  <div class="chatListDiv">

    <div class="chat-left">

      <div class="search-div mb-3 ">
        <input type="search" class="form-control" placeholder="Search or start new chat">
        <i class="fa fa-search" aria-hidden="true"></i>
      </div>
      <div class="chat-list ">
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Princy Sexana</h6>
              <label>

                Hii Somya Kaise ho?</label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>

        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Saloni</h6>
              <label>

                Hii Somya babu</label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>



        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Riya Gupta</h6>
              <label>

                Babu Khana Khaya</label>
            </div>
            <div class="chat-li3">
              <p>12:10 PM</p>

            </div>
          </li>

        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Shivani</h6>
              <label>

                Hii </label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>
        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Soni</h6>
              <label>

                Hii Handsom</label>
            </div>
            <div class="chat-li3">
              <p>Yesterday</p>

            </div>
          </li>


        </ul>

        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Princy Sexana</h6>
              <label>

                Hii Somya Kaise ho?</label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>

        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Saloni</h6>
              <label>

                Hii Somya babu</label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>



        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Riya Gupta</h6>
              <label>
                Babu Khana Khaya</label>
            </div>
            <div class="chat-li3">
              <p>12:10 PM</p>

            </div>
          </li>

        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Shivani</h6>
              <label>
                Hii </label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>
        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Soni</h6>
              <label>
                Hii Handsom</label>
            </div>
            <div class="chat-li3">
              <p>Yesterday</p>

            </div>
          </li>


        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Princy Sexana</h6>
              <label>
                Hii Somya Kaise ho?</label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>

        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Saloni</h6>
              <label>
                Hii Somya babu</label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>



        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png">
            <div class="active-chat"></div>
          </li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Riya Gupta</h6>
              <label>
                Babu Khana Khaya</label>
            </div>
            <div class="chat-li3">
              <p>12:10 PM</p>

            </div>
          </li>

        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Shivani</h6>
              <label>
                Babu thana thaya </label>
            </div>
            <div class="chat-li3">
              <p>10:10 AM</p>

            </div>
          </li>
        </ul>
        <ul class="chat-list-ul">
          <li class="chat-li1"><img src="assets/images/chat-img/user-img.png"></li>
          <li class="bottom-u">
            <div class="chat-li2">
              <h6>Soni</h6>
              <label>
                Hii Handsom</label>
            </div>
            <div class="chat-li3">
              <p>Yesterday</p>

            </div>
          </li>


        </ul>


      </div>

      <!-- my profile start-->
      <div id="profile-id" class="profile-class">
        <div class="back-profile">
          <a href="javascript:void(0)" class="" onclick="closeProfile()"><i class="fa fa-long-arrow-left"
              aria-hidden="true"></i> Profile</a>
        </div>

        <figure class="profile-figure">
          <img src="assets/images/chat-img/user-icon.png">
          <!-- <div class="change-profile"> -->
          <div class="dropdown change-profile">
            <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fa fa-camera" aria-hidden="true"></i> <br> CHANGE <br> PROFILE <br> PHOTO
            </button>
            <div class="dropdown-menu change-profile-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">view Photo</a>
              <a class="dropdown-item" href="#">Take photo</a>
              <a class="dropdown-item" href="#">Uploade photo</a>
              <a class="dropdown-item" href="#">Remove photo</a>
            </div>
          </div>

        </figure>
        <div class="name-div mt-5">
          <h6>Your Name</h6>
          <p class="mt-3">Princy</p>
        </div>
        <div class="name-div mt-3">
          <h6>About</h6>
          <p class="mt-3">Princy kumari</p>
        </div>

      </div>
    </div>
  </div>
</div>