import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {
search:any ="Search chat list"
userName  = [
  {name:"King  Waliullah"},
  {name:"Somya "},{name:"Tanveer "},{name:"Arvind sharma"},{name:"Ankita Kumari"},{name:"Sushmita"},{name:"Mohit"},{name:"Satyam"}
]
  constructor() { }

  ngOnInit(): void {
  }

}
