import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { MainService } from '../provider/main.service';
import { AuthService } from '../provider/auth.service';
import { CommonService } from '../provider/common.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService, public mainService: CommonService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          this.mainService.hideSpinner();
          // console.log(error)
          if (error.error instanceof ErrorEvent) {
            // console.log('This is client side error.')
            errorMsg = `Error: ${error.error.message}`
          } else {
            // console.log('This is server side error')
            errorMsg = `Error Code: ${error.status}, Message: ${error.message}`
            switch (error.error.responseCode) {
              case 400:
                console.log('Error 400.')
                this.mainService.errorToast(error.error.responseMessage);
                // this.mainService.logout();
                break;
              case 401:
                // this.mainService.errorToast('Unauthorized Access.');
                this.mainService.errorToast(error.error.responseMessage);
                this.authService.logout()
                break;
              case 403:
                console.log("Error 403")
                // this.mainService.errorToast('Unauthorized Access.');
                this.mainService.errorToast(error.error.responseMessage);
                this.authService.logout()
                break;
              case 500:
                this.mainService.errorToast('Internal Server Error.');
                break;
              default:
                // this.mainService.errorToast(error.error.responseMessage);
                break;
            }
          }
          // return throwError(errorMsg)
          return throwError(error)
        })
      )
  }

}
